//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
export default {
    data(){
        return{
            show_1: false,
            show_2: false,
            loading: true,
            valid_token: false,
            token: '',
            nuova_pwd: '',
            ripeti_pwd: '',
            rules_new: [
                value => (value.length >= 8) || 'Almeno 8 caratteri',
                value => !!value || 'Specificare Password'
            ],
            rules_rep: [
                value => value == this.nuova_pwd || 'Le password devono coincidere',
                value => !!value || 'Ripetere la password'
            ],
            valid: true,
            pwd_changed: false,
            empty_pwd: false
        }
    },
    mounted(){
        var token = this.$route.query.token
        axios.get(process.env.VUE_APP_API_ROOT + '/verify_reset_token/' + token)
        .then(res =>{
            this.loading = false
            if(res.status == 200){
                this.valid_token = true
                this.token = token
            }
        })
        .catch(()=>{
            this.loading = false
        })
    },
    methods:{
        submit(){
            this.$refs.form.validate()
            if(this.valid){
                this.loading = true
                if(this.nuova_pwd.length >= 6 || this.nuova_pwd == this.ripeti_pwd){
                    var json = {
                        'token': this.token,
                        'new_pwd': this.nuova_pwd
                    }
                    axios.post(process.env.VUE_APP_API_ROOT + '/change_password', json)
                    .then(()=>{
                        this.loading = false
                        this.pwd_changed = true
                        this.$router.push("/")
                    })  
                }
            }
            
        }
    }
}
